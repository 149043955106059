<template>
  <div id="lprs" style="position: relative; min-height: calc(100vh - 38px);">
    <mx-preloader style="margin-top: 16px;" :z-index="7" :logo="false" :spiner="true" :loader-status="preloader === true"/>

    <v-row v-if="Object.keys(company).length === 0">
      <v-col cols="12" sm="6" class="offset-3">
        <h1 class="text-center mt-16 display-1">{{ $t('company.not_found.phone') }}</h1>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-card flat>
          <v-tabs :ripple="false" v-model="tab" fixed-tabs>
            <v-tabs-slider :ripple="false" color="primary"></v-tabs-slider>
            <v-tab :disabled="item.disabled" :ripple="false" v-for="item in items" :key="item.id">
              {{ item.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items id="tbsh" v-model="tab" style="height: calc(100vh - 110px);">
            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12" md="6">
                    <CompanyCard :company="company"/>
                  </v-col>
                </template>

                <v-col style="margin-top: auto;" cols="12">
                  <v-card flat style="min-height: 86px;">
                    <v-card-title>
                      <v-icon class="mr-2">mdi-clipboard-account-outline</v-icon>
                      <span style="font-weight: 300;">{{ customer.first_name }} {{ customer.last_name }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-subtitle>
                      <strong>{{ $t('customer.show.gender.title') }}:</strong>
                      <span>{{ $t(`customer.show.gender.${customer.gender}`) }}</span> <br>
                      <strong>{{ $t('customer.show.identification') }}:</strong> <span>{{
                        customer.identification
                      }}</span> <br>
                      <strong>{{ $t('customer.show.email') }}:</strong> <span><a
                        :href="`mailto:${customer.email}`">{{ customer.email }}</a></span> <br>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <TableFullPersons :contact_persons="contact_persons"/>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col cols="12">
                    <TableFullAddresses :addresses="company_addresses"/>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

            <v-tab-item class="px-6" style="height: 100%; overflow-y: auto">
              <v-row style="height: 100%;">
                <template>
                  <v-col class="align-center d-flex flex-column" cols="12">
                    <h1 class="mt-16">{{company.faq.welcome_text}}</h1>
                    <v-btn v-if="questions.length === 0" @click="getQuestions" x-large class="mt-16" color="primary">{{ $t('faq.question.get') }}</v-btn>
                  </v-col>
                </template>
                <template v-if="questions.length > 0">
                  <v-col cols="12">
                    <QuestionsList :questions="questions"/>
                  </v-col>
                </template>
              </v-row>
            </v-tab-item>

          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import CompanyCard from "@/components/Company/CompanyCard";
import TableFullPersons from "@/components/Tables/TableFullPersons";
import TableFullAddresses from "@/components/Tables/TableFullAddresses";
import QuestionsList from "@/components/Faq/QuestionsList";

export default {
  components: {CompanyCard, TableFullPersons, TableFullAddresses, QuestionsList},
  data() {
    return {
      preloader: true,
      tab: 0,
      items: [
        { id: 1, name: 'Firmen', disabled: false},
        { id: 2, name: 'Ansprechpartner', disabled: false},
        { id: 3, name: 'Standorte', disabled: false},
        { id: 4, name: 'FAQ', disabled: false},
      ],
      company: {},
      customer: {},
      questions: [],
      contact_persons: [],
      company_addresses: []
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.$store.dispatch('companySearchFromCall', this.$route.query).then(() => {
        this.company = this.$store.getters.company
        if (Object.keys(this.company).length > 0) {
          this.customer = this.$store.getters.company.customer
          this.contact_persons = this.$store.getters.company.persons
          this.company_addresses = this.$store.getters.company.addresses

          if (this.customer.can_faq === 1) {
            this.tab = 3
          } else {
            this.items[3].disabled = true
          }
        }
        setTimeout(this.mxLoader, 100)
      })
    },
    getQuestions() {
      this.$store.dispatch('questionPerFaqId', this.company.faq.id).then(() => {
        this.questions = this.$store.getters.company.faq.questions
      })
    },
    mxLoader() {
      this.preloader = false
    }
  }
}
</script>
